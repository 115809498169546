import { v4 as uuidv4 } from 'uuid';
import api from '@/plugins/api';

const namespaced = true;

const state = {
  databaseMixtures:
    JSON.parse(localStorage.getItem('databaseMixtures')) || [],
  mixtures: [],
  flueGas: { species: [] },
  emptyMixture: {
    publicId: -1,
    streamId: 999,
    name: '',
    phase: 'g',
    streamType: 'Fuel',
    species: [],
    T: 293.15,
    p: 1e5,
    massFlow: 1.0,
    volumeFlow: 1.0,
    normVolumeFlow: 1.0,
    flowRateInput: 'Volume Flow',
    molarMass: null,
    elementalMassFractions: {},
    rhoN: 1.0,
    rho: 1.0,
    lambda: 1.0,
    LHVBoie: null,
    LHVNist: null,
    power: null,
    CHRatio: null,
    O2Demand: null,
    coupledToFuel: false,
    coupledFuel: null,
    coupledToChamber: false,
    coupledChamber: null,
    coupledToAmbientAirTemperature: false,
    coupledToAmbientAirComposition: false,
    fixedTemperature: false,
    pollutants: {
      NOx: {
        massFraction: 0.0,
        volumeFraction: 0.0,
        moleFraction: 0.0,
        rhoN: 2.053,
      },
    },
    fraction: 1.0, // for outlets only
  },
  streamTypes: {
    g: [
      { name: 'Fuel', color: '#ffff00', textColor: '#000000' },
      { name: 'Air', color: '#0070c0', textColor: '#ffffff' },
      { name: 'Flue Gas', color: '#92d050', textColor: '#000000' },
      { name: 'Other', color: '#e6e6e6', textColor: '#000000' },
    ],
    l: [
      { name: 'Water', color: '#0000ff', textColor: '#ffffff' },
      { name: 'Fuel', color: '#ffff00', textColor: '#000000' },
      { name: 'Waste', color: '#ffc000', textColor: '#000000' },
      { name: 'Other', color: '#e6e6e6', textColor: '#000000' },
    ],
    s: [
      { name: 'Fuel', color: '#ffff00', textColor: '#000000' },
      { name: 'Other', color: '#e6e6e6', textColor: '#000000' },
    ],
  },
  standardAmbientSpecies: [
    { formula: 'O2', volumeFraction: 0.21 },
    { formula: 'N2', volumeFraction: 0.78 },
    { formula: 'CO2', volumeFraction: 0.0003 },
    { formula: 'Ar', volumeFraction: 0.0097 },
    { formula: 'H2O', volumeFraction: 0.0 },
  ],
  ambient:
    JSON.parse(localStorage.getItem('currentProject')) !== null
      ? JSON.parse(localStorage.getItem('currentProject')).ambient
      : null,
  emptyAmbient: {
    T: 20 + 273.15,
    p: 101325,
    phi: 0.0,
    inputFraction: {
      name: 'Volume Fraction',
      unit: '%vol',
    },
    dry: { species: [] },
    wet: { species: [] },
  },
  standardFlueGasSpecies: [
    { formula: 'O2', phase: 'g' },
    { formula: 'N2', phase: 'g' },
    { formula: 'H2O', phase: 'g' },
    // { formula: 'H2O', phase: 'l' },
    { formula: 'CO', phase: 'g' },
    { formula: 'CO2', phase: 'g' },
    { formula: 'SO2', phase: 'g' },
    { formula: 'SO3', phase: 'g' },
    { formula: 'HCl', phase: 'g' },
    { formula: 'HBr', phase: 'g' },
    { formula: 'HF', phase: 'g' },
  ],
  inputFractions: [
    { name: 'Mass Fraction', unit: '%mass' },
    { name: 'Volume Fraction', unit: '%vol' },
    { name: 'Mole Fraction', unit: '%mol' },
  ],
};

const getters = {
  databaseMixtures: state => state.databaseMixtures,
  mixtures: state => state.mixtures,
  emptyMixture: state => () => {
    let newMixture = JSON.parse(JSON.stringify(state.emptyMixture));
    return newMixture;
  }, // now a function!
  emptyOutlet: (state, getters) =>
    function(numberOfOutlets = 0) {
      let newOutlet = getters.emptyMixture();
      newOutlet.publicId = uuidv4();
      newOutlet.streamId = 600 + 10 * (numberOfOutlets + 1);
      newOutlet.name = 'Outlet';
      newOutlet.streamType = 'Flue Gas';
      numberOfOutlets === 0
        ? (newOutlet.fraction = 1.0)
        : (newOutlet.fraction = 0.0);
      return newOutlet;
    }, // now a function!
  streamTypes: state => state.streamTypes,
  inputFractions: state => state.inputFractions,
  ambient: state => state.ambient,
  emptyAmbient: (state, getters, rootState, rootGetters) => () => {
    let newAmbient = JSON.parse(JSON.stringify(state.emptyAmbient));
    newAmbient.dry = getters.emptyMixture();
    newAmbient.wet = getters.emptyMixture();
    state.standardAmbientSpecies.forEach(s => {
      var index = rootState.materials.materials.findIndex(
        mat => mat.phase === 'g' && mat.formula === s.formula,
      );
      if (index !== -1) {
        var mat = rootState.materials.materials[index];
        var specie = rootGetters['materials/emptySpecies']();
        Object.keys(mat)
          .filter(key => key in specie)
          .forEach(key => (specie[key] = mat[key]));
        Object.keys(s)
          .filter(key => key in specie)
          .forEach(key => (specie[key] = s[key]));
        newAmbient.dry.species.push(
          JSON.parse(JSON.stringify(specie)),
        );
        newAmbient.wet.species.push(
          JSON.parse(JSON.stringify(specie)),
        );
      } else {
        console.log(
          `Standard ambient species not found in material database: ${s.formula}(g)`,
        );
      }
    });
    return newAmbient;
  }, // now a function!
  ambientSpecies: state => state.ambient.wet.species,
  flueGas: state => state.flueGas,
  uniqueSpecies: state => {
    var mixtures = state.mixtures;
    var allSpecies = [];
    mixtures.forEach(mixture => allSpecies.push(...mixture.species));
    allSpecies.push(...state.flueGas.species);
    var uniqueSpecies = allSpecies.filter(
      (v, i, a) =>
        a.findIndex(
          t => t.formula === v.formula && t.phase === v.phase,
        ) === i,
    );
    return uniqueSpecies;
  },
  uniqueName: (state, getters, rootState, rootGetters) => (
    name,
    mixtures,
  ) => {
    var names = mixtures.map(mix => mix.name);
    var counter = 1;
    do {
      var uniqueName = name + ` (${counter})`;
      counter += 1;
    } while (names.includes(uniqueName));
    return uniqueName;
  },
  isCoupled: state => mix => {
    var isCoupled = false;
    switch (true) {
      case mix.coupledToAmbientAirComposition:
        isCoupled = true;
        break;
      case mix.coupledToAmbientAirTemperature:
        isCoupled = true;
        break;
      case mix.coupledToFuel:
        isCoupled = true;
        break;
    }
    console.log(isCoupled);
    return isCoupled;
  },
  usedMixtures: (state, getters, rootState, rootGetters) => {
    var usedMixtures = [];
    var combustionChambers =
      rootGetters['combustionChambers/combustionChambers'];
    combustionChambers.forEach(chamber =>
      usedMixtures.push(...chamber.inletMixtures),
    );
    return usedMixtures;
  },
  assembleMixture: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => mixture => {
    var mix = JSON.parse(JSON.stringify(mixture));
    var materials = rootGetters['materials/materials'];
    mix.species = mixture.species.map(s => {
      var index = materials.findIndex(
        mat => mat.publicId === s.publicId,
      );
      if (index !== -1) {
        return Object.assign(s, materials[index]);
      } else {
        console.log(
          `Species (${s.name} | ${s.cas} | ${s.formula}) not found in materials`,
        );
        console.log(s);
        return s;
      }
    });
    return mix;
  },
  disassembleMixture: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => mixture => {
    var mix = JSON.parse(JSON.stringify(mixture));
    mix.species = mixture.species.map(mat => {
      var s = rootGetters['materials/emptySpecies']();
      Object.keys(mat)
        .filter(key => key in s)
        .forEach(key => (s[key] = mat[key]));
      return s;
    });
    return mix;
  },
  assembleAmbient: (state, getters) => ambient => {
    var amb = JSON.parse(JSON.stringify(ambient));
    amb.dry = getters.assembleMixture(amb.dry);
    amb.wet = getters.assembleMixture(amb.wet);
    return amb;
  },
  disassembleAmbient: (state, getters) => ambient => {
    var amb = JSON.parse(JSON.stringify(ambient));
    amb.dry = getters.disassembleMixture(amb.dry);
    amb.wet = getters.disassembleMixture(amb.wet);
    return amb;
  },
};

const actions = {
  getDatabaseMixtures({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('mixtures/').then(
        response => {
          var mixtures = response.data.mixtures;
          commit('setDatabaseMixtures', mixtures);
          localStorage.setItem(
            'databaseMixtures',
            JSON.stringify(mixtures),
          );
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  addDatabaseMixture({ commit, getters }, { mixture, index = null }) {
    return new Promise((resolve, reject) => {
      if (Array.isArray(mixture)) {
        mixture.forEach(mix => {
          if (getters.isCoupled(mix)) {
            mix.coupledToFuel = false;
            mix.coupledFuel = null;
            mix.coupledToAmbientAirComposition = false;
            mix.coupledToAmbientAirTemperature = false;
            mix.coupledToChamber = false;
            mix.coupledChamber = null;
            alert(
              'Mixture Coupling is removed for the mixture saved to database',
            );
          }
        });
      } else {
        if (getters.isCoupled(mixture)) {
          mixture.coupledToFuel = false;
          mixture.coupledFuel = null;
          mixture.coupledToAmbientAirComposition = false;
          mixture.coupledToAmbientAirTemperature = false;
          mixture.coupledToChamber = false;
          mixture.coupledChamber = null;
          console.log('Mixture has coupling');
        }
      }
      console.log('add');
      api.post('mixtures/', mixture).then(
        response => {
          console.log('Posted');
          if ('mixtures' in response.data) {
            commit('addDatabaseMixtures', {
              mixtures: response.data.mixtures,
              index,
            });
          } else if ('mixture' in response.data) {
            commit('addDatabaseMixture', {
              mixture: response.data.mixture,
              index,
            });
          }
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  updateDatabaseMixture({ commit }, updatedMixture) {
    return new Promise((resolve, reject) => {
      if (
        confirm(
          'Are you sure you want to update this mixture in database?',
        )
      ) {
        api
          .put(`mixtures/${updatedMixture.publicId}`, updatedMixture)
          .then(
            response => {
              commit('updateDatabaseMixture', response.data.mixture);
              resolve(response);
            },
            error => reject(error),
          );
      } else {
        reject({
          response: {
            data: { msg: 'Updating Material aborted by user.' },
          },
        });
      }
    });
  },
  duplicateDatabaseMixture(
    { dispatch, getters },
    mixtureToDuplicate,
  ) {
    return new Promise((resolve, reject) => {
      var duplicatedMixture = JSON.parse(
        JSON.stringify(mixtureToDuplicate),
      );
      duplicatedMixture.publicId = uuidv4();
      duplicatedMixture.name = getters.uniqueName(
        mixtureToDuplicate.name,
        getters.databaseMixtures,
      );
      const index =
        getters.mixtures.findIndex(
          mix => mix.publicId === mixtureToDuplicate.publicId,
        ) + 1;
      dispatch('addDatabaseMixture', {
        mixture: duplicatedMixture,
        index,
      })
        .then(response => {
          resolve({ data: { msg: 'Mixture duplicated!' } });
        })
        .catch(error => {
          reject({
            response: {
              data: { msg: 'Failed to duplicate mixture...' },
            },
          });
        });
    });
  },
  deleteDatabaseMixture({ commit }, publicId) {
    return new Promise((resolve, reject) => {
      if (
        confirm(
          'Are you sure you want to delete this mixture from Database?',
        )
      ) {
        api.delete(`mixtures/${publicId}`).then(
          response => {
            commit('deleteDatabaseMixture', publicId);
            resolve(response);
          },
          error => reject(error),
        );
      } else {
        reject({
          response: {
            data: {
              msg: 'Mixture deletion from database aborted by user.',
            },
          },
        });
      }
    });
  },
  destroyDatabaseMixtures({ commit }) {
    localStorage.removeItem('databaseMixtures');
    commit('setDatabaseMixtures', []);
  },
  setMixtures({ commit }, mixtures) {
    commit('setMixtures', mixtures);
  },
  setAmbient({ commit }, ambient) {
    commit('setAmbient', ambient);
  },
  setFlueGas({ commit, state }, flueGas) {
    commit('setFlueGas', flueGas);
  },
  addMixture({ commit, dispatch }, { mixture, index }) {
    return new Promise((resolve, reject) => {
      try {
        var msg;
        if (Array.isArray(mixture)) {
          commit('addMixtures', { mixtures: mixture, index });
          mixture.length > 1
            ? (msg = `${mixture.length} Mixtures added!`)
            : (msg = 'Mixture added!');
        } else {
          commit('addMixture', { mixture, index });
          msg = 'Mixture added!';
        }
        dispatch('projects/setUpToDate', false, { root: true });
        resolve({ data: { msg } });
      } catch {
        reject({
          response: { data: { msg: 'Failed to add mixture...' } },
        });
      }
    });
  },
  deleteMixture(
    { dispatch, commit, getters, rootGetters },
    publicId,
  ) {
    var snack;
    var deleteConfirmed = false;
    var mixToDelete = getters.mixtures.find(
      mix => mix.publicId === publicId,
    );
    if (
      confirm(
        `Are you sure you want to delete ${mixToDelete.streamId}|${mixToDelete.name}?`,
      )
    ) {
      deleteConfirmed = true;
      // check if mixture is fuel and if there are coupled air mixtures
      if (mixToDelete.streamType === 'Fuel') {
        getters.mixtures.forEach(mix => {
          if (mix.coupledToFuel) {
            if (mix.coupledFuel.publicId === publicId) {
              deleteConfirmed = confirm(
                `The Mixture ${mix.streamId}|${mix.name} is coupled to the mixture to delete (${mixToDelete.streamId}|${mixToDelete.name}). The coupled mixture will be decoupled. Continue?`,
              );
              if (deleteConfirmed) {
                mix.coupledToFuel = false;
                mix.coupledFuel = null;
                snack = {
                  text: `${mix.streamId}|${mix.name} decoupled from ${mixToDelete.streamId}|${mixToDelete.name}`,
                  color: 'success',
                };
                dispatch('misc/setSnack', snack, { root: true });
              }
            }
          }
        });
      }
      // search all combustion chambers and check if it is in use
      var chambers =
        rootGetters['combustionChambers/combustionChambers'];
      chambers.forEach(chamber => {
        var index = chamber.inletMixtures.findIndex(
          mix => mix.publicId === publicId,
        );
        if (index !== -1) {
          deleteConfirmed = confirm(
            `Mixture ${mixToDelete.streamId}|${mixToDelete.name} is used in ${chamber.name} as inlet stream and will be removed upon deletion. Continue?`,
          );
          if (deleteConfirmed) {
            chamber.inletMixtures.splice(index, 1);
            snack = {
              text: `${mixToDelete.streamId}|${mixToDelete.name} removed from ${chamber.name}`,
              color: 'success',
            };
            dispatch('misc/setSnack', snack, { root: true });
          }
        }
      });
    }
    if (deleteConfirmed) {
      commit('deleteMixture', publicId);
      snack = {
        text: `${mixToDelete.streamId}|${mixToDelete.name} deleted`,
        color: 'success',
      };
      dispatch('misc/setSnack', snack, { root: true });
    } else {
      snack = {
        text: `Deletion of ${mixToDelete.streamId}|${mixToDelete.name} canceled by user`,
        color: 'error',
      };
      dispatch('misc/setSnack', snack, { root: true });
    }
  },
  deleteAllMixtures({ commit }) {
    if (
      confirm(
        'Are you sure you want to delete all (!!!) mixtures from database?',
      )
    ) {
      commit('removeAllMixtures');
    }
  },
  updateMixture({ commit, dispatch }, updatedMixture) {
    return new Promise((resolve, reject) => {
      if (confirm('Are you sure you want to update this mixture?')) {
        commit('updateMixture', updatedMixture);
        dispatch('updateReference', updatedMixture);
        dispatch('updateCoupledMixtures');
        dispatch('projects/setUpToDate', false, { root: true });
        resolve({ data: { msg: 'Mixture updated!' } });
      } else {
        reject({
          response: {
            data: { msg: 'Updating Mixture aborted by user.' },
          },
        });
      }
    });
  },
  duplicateMixture({ dispatch, getters }, mixtureToDuplicate) {
    return new Promise((resolve, reject) => {
      var duplicatedMixture = JSON.parse(
        JSON.stringify(mixtureToDuplicate),
      );
      duplicatedMixture.publicId = uuidv4();
      duplicatedMixture.name = getters.uniqueName(
        mixtureToDuplicate.name,
        getters.mixtures,
      );
      duplicatedMixture.coupledChamber = null;
      duplicatedMixture.coupledToChamber = null;
      const index =
        getters.mixtures.findIndex(
          mix => mix.publicId === mixtureToDuplicate.publicId,
        ) + 1;
      dispatch('addMixture', {
        mixture: duplicatedMixture,
        index,
      })
        .then(response => {
          resolve({ data: { msg: 'Mixture duplicated!' } });
        })
        .catch(error => {
          reject({
            response: {
              data: { msg: 'Failed to duplicate mixture...' },
            },
          });
        });
    });
  },
  initFlueGas({ commit, state, getters, rootGetters }) {
    var flueGas = getters.emptyMixture();
    var materials = rootGetters['materials/materials'];
    state.standardFlueGasSpecies.forEach(s => {
      var index = materials.findIndex(
        mat => mat.phase === s.phase && mat.formula === s.formula,
      );
      if (index !== -1) {
        var mat = materials[index];
        var specie = rootGetters['materials/emptySpecies']();
        Object.keys(mat)
          .filter(key => key in specie)
          .forEach(key => (specie[key] = mat[key]));
        Object.keys(s)
          .filter(key => key in specie)
          .forEach(key => (specie[key] = s[key]));
        flueGas.species.push(specie);
      } else {
        console.log(
          `Could not add standard flue gas species ${s.formula}(${s.phase}), because it was not found in material database.`,
        );
      }
    });
    commit('setFlueGas', flueGas);
  },
  getMixture({ rootGetters, getters }, { mixture, inputFraction }) {
    var assembledMix = getters.assembleMixture(mixture);
    return new Promise((resolve, reject) => {
      api
        .post('mixtures/create', {
          mixture: assembledMix,
          inputFraction,
        })
        .then(
          response => {
            response.data.mixture = getters.disassembleMixture(
              response.data.mixture,
            );
            resolve(response);
          },
          error => {
            reject(error);
          },
        );
    });
  },
  initAmbient({ commit, getters, dispatch }) {
    return new Promise((resolve, reject) => {
      var ambient = getters.emptyAmbient();
      dispatch('getAmbientComposition', ambient)
        .then(response => {
          commit('setAmbient', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('setAmbient', ambient);
          reject(error);
        });
    });
  },
  getAmbientComposition({ getters }, ambient) {
    var amb = getters.assembleAmbient(ambient);
    return new Promise((resolve, reject) => {
      api.post('mixtures/ambientComposition', { ambient: amb }).then(
        response => {
          var amb = getters.disassembleAmbient(response.data);
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },
  updateCoupledMixtures({ getters, dispatch }) {
    getters.mixtures.forEach(mixture => {
      // check if mixture is coupled to ambient temperature
      if (mixture.coupledToAmbientAirTemperature) {
        mixture.T = getters.ambient.T;
      }
      // check if mixture is coupled to ambient composition
      if (mixture.coupledToAmbientAirComposition) {
        mixture.species = getters.ambient.wet.species;
      }
      // check if mixture is coupled to fuel
      if (mixture.coupledToFuel) {
        var publicIdFuel = mixture.coupledFuel.publicId;
        var fuel = getters.mixtures.find(
          mix => mix.publicId === publicIdFuel,
        );
        Object.assign(mixture.coupledFuel, fuel);
        dispatch('calcMassFlowFromLambda', mixture);
      }
    });
  },
  updateReferences({ getters, dispatch }) {
    // update references of all mixtures
    var mixtures = getters.mixtures;
    mixtures.forEach(mix => {
      dispatch('updateReference', mix);
    });
  },
  updateReference({ dispatch, rootGetters }, mixture) {
    var combustionChambers =
      rootGetters['combustionChambers/combustionChambers'];
    var found = false;
    combustionChambers.some(chamber => {
      var index = chamber.inletMixtures.findIndex(
        inlet => inlet.publicId === mixture.publicId,
      );
      if (index !== -1) {
        chamber.inletMixtures[index] = mixture;
        found = true;
        return true; // break .some loop
      }
    });
    if (found) {
      dispatch('combustionChambers/updateAll', {}, { root: true });
    } else {
      console.log(
        'No reference to mixture found in current combustion chambers',
      );
    }
  },
  calcMassFlowFromLambda(context, mixture) {
    return new Promise((resolve, reject) => {
      var oxygenCarried = -mixture.O2Demand;
      if (oxygenCarried <= 0) {
        reject({
          response: {
            data: {
              msg:
                'Mixture carries no oxygen. Massflow not calculated',
            },
          },
        });
        return;
      } else if (mixture.coupledFuel === null) {
        reject({
          response: {
            data: {
              msg:
                'No fuel coupled to mixture. Massflow not calculated',
            },
          },
        });
        return;
      } else if (mixture.coupledFuel.O2Demand <= 0) {
        reject({
          response: {
            data: {
              msg:
                'Coupled fuel has no oxygen demand. Massflow set to zero',
            },
          },
        });
        mixture.massFlow = 0.0;
        return;
      }
      var lambda = mixture.lambda;
      var fuelMassFlow = mixture.coupledFuel.massFlow;
      var O2Demand = mixture.coupledFuel.O2Demand;
      var massFlowOxStoich = fuelMassFlow * O2Demand;
      mixture.massFlow = (lambda * massFlowOxStoich) / oxygenCarried;
      mixture.normVolumeFlow = mixture.massFlow / mixture.rhoN;
      resolve({
        data: { msg: 'Massflow successfully calculated' },
      });
    });
  },
};

const mutations = {
  setDatabaseMixtures: (state, mixtures) =>
    (state.databaseMixtures = mixtures),
  addDatabaseMixture: (state, { mixture, index }) => {
    if (index === -1 || index === undefined || index === null) {
      state.databaseMixtures.push(mixture);
    } else {
      state.databaseMixtures.splice(index, 0, mixture);
    }
  },
  addDatabaseMixtures: (state, { mixtures, index }) => {
    if (index === -1 || index === undefined || index === null) {
      state.databaseMixtures.push(...mixtures);
    } else {
      state.databaseMixtures.splice(index, 0, ...mixtures);
    }
  },
  updateDatabaseMixture: (state, updatedMixture) => {
    const index = state.databaseMixtures.findIndex(
      mix => mix.publicId === updatedMixture.publicId,
    );
    if (index != -1) {
      state.databaseMixtures.splice(index, 1, updatedMixture);
    }
  },
  deleteDatabaseMixture: (state, publicId) => {
    const index = state.databaseMixtures.findIndex(
      mix => mix.publicId === publicId,
    );
    if (index !== -1) {
      state.databaseMixtures.splice(index, 1);
    }
  },
  setMixtures: (state, mixtures) => (state.mixtures = mixtures),
  setAmbient: (state, ambient) => (state.ambient = ambient),
  setFlueGas: (state, flueGas) => (state.flueGas = flueGas),
  addMixture: (state, { mixture, index }) => {
    if (index === -1 || index === undefined || index === null) {
      state.mixtures.push(mixture);
    } else {
      state.mixtures.splice(index, 0, mixture);
    }
  },
  addMixtures: (state, { mixtures, index }) => {
    if (index === -1 || index === undefined || index === null) {
      state.mixtures.push(...mixtures);
    } else {
      state.mixtures.splice(index, 0, ...mixtures);
    }
  },
  updateMixture: (state, updatedMixture) => {
    const index = state.mixtures.findIndex(
      mixture => mixture.publicId === updatedMixture.publicId,
    );
    if (index !== -1) {
      state.mixtures.splice(index, 1, updatedMixture);
    }
  },
  deleteMixture: (state, publicId) => {
    const index = state.mixtures.findIndex(
      mix => mix.publicId === publicId,
    );
    if (index !== -1) {
      state.mixtures.splice(index, 1);
    }
  },
  removeAllMixtures: state => (state.mixtures = []),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
